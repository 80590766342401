import NavBar from "../components/general/NavBar";
import { goalOrientations } from "../config/goalorientations";
import userApi from "../api/user";
import { useCourse } from "../contexts/CourseProvider";
import { useState } from "react";
import xapiverbs from "../config/xapiverbs";
import { saveEvent } from "../api/userevents";

const PostGoalSelection = ({ previousGoal, nextGoal, onSubmit }) => {
  const [selected, setSelected] = useState("");
  const [custom, setCustom] = useState("");

  const options = [
    "It corresponds better corresponds to this goal",
    "It corresponds better to my goal this week",
    "I want to explore the goals",
    "I wanted to test how the interface looks with this goal",
  ];
  return (
    <div className="z-[51] fixed top-0 left-0 flex flex-col h-screen w-screen bg-background">
      <NavBar />
      <div className="flex flex-col grow bg-card">
        <div className="flex flex-row grow mx-12 mt-12">
          <div className="w-full max-w-6xl mx-auto bg-background border-2 border-background_fade flex flex-col justify-center p-8">
            <div className="w-full text-center">
              <div className="pb-4 px-4 text-4xl">Updating Dashboard</div>
              <div>
                Previous Goal: {goalOrientations[previousGoal].text}
                <br />
                New Goal: {goalOrientations[nextGoal].text}
                <br />
                <br />
              </div>
              <div>
                <h2 className="text-2xl font-semibold mb-4 text-center">Why did you choose this goal?</h2>
                <p>Your choice here will be encrypted and stored on secure UU servers. Your data is safe.</p>
              </div>
              <div className="flex flex-col gap-3">
                {options.map((option, index) => (
                  <div
                    key={index}
                    onClick={() => setSelected(option)}
                    className={`border-2 p-3 cursor-pointer rounded-lg hover:bg-secondary transition ${
                      selected === option ? "border-black" : "border-background_fade"
                    }`}
                  >
                    {option}
                  </div>
                ))}
                <input
                  type="text"
                  placeholder="Other (please specify)"
                  value={custom}
                  onChange={(e) => {
                    setCustom(e.target.value);
                    setSelected("");
                  }}
                  className="border-2 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent"
                />
              </div>

              <button
                onClick={() => onSubmit(custom || selected)}
                disabled={!selected && !custom}
                className="mt-6 w-full p-3 bg-accent text-white rounded-lg shadow-md disabled:opacity-50 disabled:cursor-not-allowed hover:scale-105 transition"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ChooseGoalOrientationView = (props) => {
  const { course, conceptScores, goalOrientation } = useCourse();
  const [selected, setSelected] = useState("");
  const [showFollowUp, setShowFollowUp] = useState(false);
  const [initialGoal, setInitialGoal] = useState("");

  const saveReason = (reason) => {
    setShowFollowUp(false);
    props.setGoalOrientation(selected, true);

    saveEvent(-1, course.courseid, xapiverbs.ANSWERED, {
      "type": "goal-reason",
      "text": reason,
      "goalOrientation": selected,
      "conceptScores": conceptScores,
    })

  }

  const submit = async () => {
    userApi.setGoalOrientation(course.courseid, selected).then(() => {

      setInitialGoal(goalOrientation);

      setShowFollowUp(true);
      props.setGoalOrientation(selected, false);

      saveEvent(-1, course.courseid, xapiverbs.GOALCHANGED, {
        goalOrientation: selected,
        previousGoalOrientation: goalOrientation,
        "conceptScores": conceptScores,
      });
    });
  };

  const multipleChoiceOptions = Object.keys(goalOrientations).map((key) => {
    return (
      <div
        key={key}
        onClick={() => setSelected(key)}
        className={`border-2 p-4 w-64 cursor-pointer hover:bg-secondary hover:scale-110 duration-200 ${
          key == selected ? "border-black" : "border-background_fade"
        }`}
      >
        <div className="text-lg pb-2">{goalOrientations[key]["text"]}</div>
        <div className="text-sm">{goalOrientations[key]["explaination"]}</div>
      </div>
    );
  });

  if (showFollowUp) {
    return <PostGoalSelection previousGoal={initialGoal} nextGoal={selected} onSubmit={saveReason} />;
  }

  return (
    <div className="z-[51] fixed top-0 left-0 flex flex-col h-screen w-screen bg-background">
      <NavBar />
      <div className="flex flex-col grow bg-card">
        <div className="flex flex-row grow mx-12 mt-12">
          <div className="w-full max-w-6xl mx-auto bg-background border-2 border-background_fade flex flex-col justify-center p-8">
            <div className="w-full text-center">
              <div className="pb-4 px-4 text-4xl">Your Goal</div>
              <div className="pb-10 text-md">
                Your current goal is: <b>{goalOrientations[goalOrientation].text}</b>
              </div>
              {selected != null && (
                <div className="pb-8 px-4">
                  <b>{goalOrientations[props.suggestedGoalOrientation]}</b>.
                </div>
              )}
            </div>

            <div className="mx-auto grid grid-cols-2 gap-4 border-1">{multipleChoiceOptions}</div>
          </div>
        </div>
      </div>
      <div className="fixed bottom-4 left-1/2 -translate-x-1/2 flex flex-row">
        <button
          onClick={submit}
          className="p-4 w-fit bg-accent min-w-[100px] text-center shadow-default rounded-lg cursor-pointer hover:scale-110 duration-200 self-end"
        >
          Confirm
        </button>
      </div>
    </div>
  );
};

export default ChooseGoalOrientationView;
