import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { saveEvent } from "../../../api/userevents";
import * as acttypes from "../../../config/activitytypes.js";
import xapiverbs from "../../../config/xapiverbs";
import { useCourse } from "../../../contexts/CourseProvider";
import useKMAdaptations from "../../../hooks/useKMAdaptations";
import { conceptFeedbackFromConceptData } from "../../../utils/feedback";
import Ping from "../../general/Ping";
import ConceptCardDisabled from "./ConceptCardDisabled";
import ConceptCardExpanded from "./ConceptCardExpanded";
import ConceptHeader from "./ConceptHeader";
import { scoringMethod } from "../../../config/experimentgroups.js";

const ConceptCard = ({ concept, lg, disabled = false }) => {
  const cardRef = useRef();
  const [hovering, setHovering] = useState(false);
  const [hoveringData, setHoveringDate] = useState(null);
  const [feedbackIconReturned, setFeedbackIconReturned] = useState(null);
  const {
    course,
    getMisconceptionsWithActivities,
    conceptScores,
    activities,
    activeConcept,
    setActiveConcept,
    activeLearningGoal,
    setActiveLearningGoal,
    goalOrientation,
  } = useCourse();

  const isActive = activeConcept?.id === concept?.id && activeLearningGoal?.lgid === lg.lgid;

  let conceptActivities = concept.activity_ids.map((id) => activities?.find((activity) => activity.activityid === id));
  const conceptFeedback = goalOrientation && conceptFeedbackFromConceptData(concept, conceptScores[concept.id], goalOrientation);
  let conceptLearningActivities = concept.activity_ids
    .map((id) => activities?.find((activity) => activity.activityid === id && acttypes.isLearning(activity.activitytype)))
    .filter((activity) => activity !== undefined);
  const activityProgress =
    (conceptLearningActivities.filter((act) => act.completed).length / conceptLearningActivities.length) * 100 || 0;

  const { concept_border, display_class_bar } = useKMAdaptations(concept, hovering, isActive, activityProgress);

  const [cardBgColor, setCardBgColor] = useState("bg-accent");

  // Handles the onClick event of the concept card
  const handleClick = () => {
    if (!isActive) {
      setActiveConcept(concept);
      setActiveLearningGoal(lg);
      setHovering(false);
      saveEvent(-1, course.courseid, xapiverbs.INTERACTED, {
        knowledgemap: true,
        clicked_concept_id: concept.id,
        learning_goal_id: lg.lgid,
        concept_score: conceptScores[concept.id],
        concept_feedback_icon: feedbackIconReturned,
      });
    }
  };

  const onFeedbackIcon = (feedback) => {
    setFeedbackIconReturned(feedback);
  };

  // Handles the click event for the expanded concept card
  const handleExpandedClick = () => {
    setActiveConcept(null);
    setActiveLearningGoal(null);
  };

  // Handles the hover event for the concept card
  const handleHoverStart = () => {
    setHovering(true);
  };

  useEffect(() => {
    if (hovering) {
      setHoveringDate(Date.now());
    }
    if (!hovering) {
      // if hoveringdate is more than 1 second ago, then save event
      if (hoveringData && Date.now() - hoveringData > 500) {
        saveEvent(-1, course.courseid, xapiverbs.INTERACTED, {
          knowledgemap: true,
          hovered_concept_id: concept.id,
          learning_goal_id: lg.lgid,
          concept_score: conceptScores[concept.id],
          concept_feedback_icon: feedbackIconReturned,
        });
      }
      setHoveringDate(false);
    }

    updateBgColor();
  }, [hovering]);

  const updateBgColor = () => {
    if (display_class_bar) {
      // const scoring_method = scoringMethod(Number(course.experimentgroup));
      const score = conceptScores[concept.id];
      if (score?.userscore && (score.userscore / score.maxscore < 0.2 || score.userscore < score.avg_score)) {
        setCardBgColor("bg-warning");
      } else if (score && (score.userscore / score.maxscore >= 0.8 || score.userscore >= score.avg_score)) {
        setCardBgColor("bg-completed");
      } else {
        setCardBgColor("bg-accent");
      }
    }

    // TODO: Also set the Border Color and remove old logic
  };

  // Get all child misconceptions of a concept with the score
  const numOfScoredMisconceptions = getMisconceptionsWithActivities(concept.id).filter(
    (con) => con.activities.some((act) => !act.completed) && conceptScores[con.id]?.userscore > 0
  ).length;

  if (disabled) return <ConceptCardDisabled concept={concept} />;

  if (concept.type == "fauxconcept" && numOfScoredMisconceptions <= 0) return null;

  return (
    <div>
      <motion.div
        ref={cardRef}
        className={`${cardBgColor} relative shadow-default text-txt_primary relative cursor-pointer rounded min-w-[240px] max-w-[240px] text-center`}
        style={{
          ...concept_border,
          zIndex: isActive ? 50 : null,
          maxWidth: isActive ? null : 240,
          minHeight: 66,
        }}
        onClick={handleClick}
        onHoverStart={handleHoverStart}
        onHoverEnd={() => setHovering(false)}
      >
        <Ping num={numOfScoredMisconceptions} />
        <ConceptHeader
          conceptFeedback={conceptFeedback}
          onFeedbackIcon={onFeedbackIcon}
          isActive={isActive}
          concept={concept}
          hovering={hovering}
          activityProgress={activityProgress}
        />
        {isActive && (
          <ConceptCardExpanded
            concept={concept}
            bgColor={cardBgColor}
            cardRef={cardRef?.current}
            conceptBorder={concept_border}
            handleClick={handleExpandedClick}
            activityProgress={activityProgress}
          />
        )}
      </motion.div>
    </div>
  );
};

export default ConceptCard;
