import DOMPurify from "dompurify";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import generalApi from "../../../api/general";

function OptionSelector({ options, value, onChange, disable }) {
  return (
    <select disabled={disable} value={value} onChange={onChange} className="border hover:border-gray-500 rounded shadow">
      <option value="">Select an answer...</option>
      {options.map((option, index) => (
        <option key={index} value={index}>
          {option}
        </option>
      ))}
    </select>
  );
}

function ReadAndAnswerBlanks({ activityid, questiondata, showAnswer, onSubmit, onLoadExisting }) {
  const sanitizedQuestion = DOMPurify.sanitize(questiondata.text);
  const [userResponses, setUserResponses] = useState({});
  const [locked, setLocked] = useState(false);
  const [answerAlreadyCorrect, setAnswerAlreadyCorrect] = useState(false);
  const [answerAlreadyWrong, setAnswerAlreadyWrong] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const params = useParams();

  const handleAnswerChange = (placeholder, event) => {
    const selectedAnswer = parseInt(event.target.value);
    const blank = questiondata.answerBlanks.find((b) => b.placeholder === placeholder);
    const newUserResponses = { ...userResponses };
    newUserResponses[blank.placeholder] = selectedAnswer;
    setUserResponses(newUserResponses);
  };

  const updateAnswers = () => {
    generalApi.getActivityAnswer(activityid, params.courseid).then((activityAnswers) => {
      const prev_selected = activityAnswers?.data?.results?.answer?.userBlanks;
      const prev_success = activityAnswers?.data?.results?.success;

      if (prev_selected) {
        setUserResponses((prev) => ({ ...prev, ...prev_selected }));
        setAnswerAlreadyCorrect(prev_success);
        setAnswerAlreadyWrong(!prev_success);
        onLoadExisting(activityid);
        if (prev_success) {
          setLocked(true);
        }
      }
    });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
    }, 1000); // 1 second delay

    if (answerAlreadyCorrect) {
      setFeedback("Your answer is correct.");
    }
    if (answerAlreadyWrong) {
      setFeedback("Your answer is not completely correct. Come back to this exercise later to retry.");
    }
    return () => clearTimeout(timeout);
  }, [answerAlreadyCorrect, answerAlreadyWrong]);

  useEffect(() => {
    updateAnswers();
    answerAlreadyCorrect && setLocked(true);
  }, []);

  const unlock = () => {
    setLocked(false);
  };

  useEffect(() => {
    if (isSubmitted) {
      updateAnswers(); // Now trigger updateAnswers when submission is marked as complete
      setIsSubmitted(false);  // Reset the flag after update
    }
  }, [isSubmitted]);

  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  const submit = async () => {
    onSubmit(activityid, userResponses);
    setFeedback("Checking answer...");
    await delay(1000); 
    updateAnswers();
  };

  return (
    <div className="text-left mx-10">
      <p className="text-gray-500 text-sm mb-0 mt-10">Question</p>
      <hr />
      <div className="text-xl font-medium">Fill in the blanks in the following statement: </div>

      <div className="mb-4 text-lg font-medium">
        {sanitizedQuestion.split(/\$([^$]+)\$/).map((part, index) => {
          if (index % 2 === 0) {
            return <span key={index} dangerouslySetInnerHTML={{ __html: part }}></span>;
          } else {
            const placeholder = part;
            const blank = questiondata.answerBlanks.find((b) => b.placeholder === placeholder);
            return (
              <OptionSelector
                key={index}
                options={blank.options}
                value={userResponses[blank.placeholder]}
                disable={showAnswer || locked || answerAlreadyCorrect}
                onChange={(e) => handleAnswerChange(placeholder, e)}
              />
            );
          }
        })}
      </div>

      {showAnswer && (
        <div>
          {sanitizedQuestion &&
            (() => {
              let updatedText = sanitizedQuestion;

              questiondata.answerBlanks.forEach((blank) => {
                const placeholder = new RegExp(`\\$${blank.placeholder}\\$`, "g");
                const correctOption = blank.options[blank.answerScores.indexOf(1)];
                updatedText = updatedText.replace(
                  placeholder,
                  `<span style="text-decoration: underline;">${correctOption}</span>`
                );
              });
              return <p dangerouslySetInnerHTML={{ __html: updatedText }} />;
            })()}
        </div>
      )}

      {!showAnswer && !locked && (
        <div
          className="btn cursor-pointer bg-accent hover:bg-amber-400 duration-500 text-white font-bold px-4 rounded w-20 text-center"
          onClick={() => submit()}
        >
          Submit
        </div>
      )}

      <div>{feedback}</div>
    </div>
  );
}

export default ReadAndAnswerBlanks;
