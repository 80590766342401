import { useRef, useEffect, useState } from "react";
import { useActivity } from "../../../contexts/ActivityProvider";
import { useCourse } from "../../../contexts/CourseProvider";
import CompleteActivityButton from "./CompleteActivityButton";
import FullScreenButton from "./FullScreenButton";
import GoBackButton from "./GoBackButton";
import ResetAnswerButton from "./ResetAnswerButton";

const ModalHeader = ({ minTimeSecs = 8, showResetButton = null }) => {
  const { activity, loadingMainInfo } = useActivity();
  const {course} = useCourse();
  const [minTimeElapsed, setMinTimeElapsed] = useState(false);
  const [timeSinceLoad, setTimeSinceLoad] = useState(0);
  const timeSinceLoadRef = useRef(0);

  useEffect(() => {
    var timer = setInterval(() => {
      timeSinceLoadRef.current += 1;
      if (timeSinceLoadRef.current > minTimeSecs) {
        setMinTimeElapsed(true);
      }
    }, 1000);
    return function cleanup() {
      clearInterval(timer);
    };
  });

  return (
    <div className="px-4 py-2 sticky w-full h-[60px] flex border-b-2 border-card_dark justify-between items-center">
      <CompleteActivityButton active={minTimeElapsed} />
      <div className="text-center font-MS truncate mx-4 min-w-[100px]">{activity.activitytitle}</div>
      {/* <div className="h-[2px] bg-black">&nbsp;</div>       */}
      <div className="flex gap-4">
        {showResetButton && <ResetAnswerButton activityid={activity.activityid} courseid={course.courseid} />}
        <FullScreenButton />
        <GoBackButton minTimeCompleted={minTimeElapsed} timeSinceLoaded={timeSinceLoadRef.current} />
      </div>
    </div>
  );
};

export default ModalHeader;
