import { useState } from "react";
import NavBar from "../components/general/NavBar";
import { useCourse } from "../contexts/CourseProvider";

const ChooseSCView = ({ onConfirm }) => {
  const [selected, setSelected] = useState("");

  const handleClick = () => {
    if (selected.length>0){
      onConfirm(selected);
    }
  }
  
  return (
    <div className="z-[51] fixed top-0 left-0 flex flex-col h-screen w-screen bg-background">
      <NavBar />
      <div className="flex flex-col grow bg-card">
        <div className="flex flex-row grow mx-12 mt-12">
          <div className="w-full max-w-6xl mx-auto bg-background border-2 border-background_fade flex flex-col justify-center p-8">
            <div className="w-full text-center">
              <div className="pb-4 px-4 text-4xl">Select Dashboard</div>
              <div className="pb-10 text-md">StudyLens now support Personal Dashboard and Social Dashboard. </div>
            </div>

            <div className="mx-auto grid grid-cols-2 gap-4 border-1">
              <div
                className={`border-2 p-4 w-64 cursor-pointer hover:bg-secondary hover:scale-110 duration-200 ${selected === "personal-dashboard" ? "border-black" : ""}`}
                onClick={() => setSelected("personal-dashboard")}
              >
                <div className="text-lg pb-2">Personal Dashboard</div>
                <div className="text-sm">Keep track of your progress on individual topics. </div>
              </div>
              <div
                className={`border-2 p-4 w-64 cursor-pointer hover:bg-secondary hover:scale-110 duration-200 ${selected === "class-dashboard" ? "border-black" : ""}`}
                onClick={() => setSelected("class-dashboard")}
              >
                <div className="text-lg pb-2">Social Dashboard</div>
                <div className="text-sm">Compare your progress with the average progress of the class on individual topics.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed bottom-16 left-1/2 -translate-x-1/2 flex flex-row">
        <button
          onClick={handleClick}
          className="p-4 w-fit bg-accent min-w-[100px] text-center shadow-default rounded-lg cursor-pointer hover:scale-110 duration-200 self-end"
        >
          Confirm
        </button>
      </div>
    </div>
  );
};

export default ChooseSCView;
