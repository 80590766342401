const activities_learning = ["learning_material_factsheet", "learning_material_video", "learning_material_book", "learning_material_interactive", "learning_material_audio", "learning_material_quiz", "learning_material_article", "learning_material_notworkingarticle"]
const activities_paws = ["learning_material_paws_tracing","learning_material_paws_reading","learning_material_paws_animated","learning_material_paws_challenges","learning_material_paws_parsons"]
const activities_onboarding = ["onboarding_activity"]
const activities_exercise = ["learning_material_readandanswer", "learning_material_exercise"]
const activities_sub = ["readandanswer_reading", "readandanswer_mcq", "readandanswer_open", "readandanswer_blanks", "onboarding_slide", "onboarding_question", "quiz_question", "exercise_question"]

export const isLearning=(acttype)=>{
  return activities_learning.includes(acttype) || activities_paws.includes(acttype);
}
export const isExercise=(acttype)=>{
  return activities_exercise.includes(acttype);
}