// Maintain a provider to man

import { useState, useEffect } from "react";
import { useParams } from "react-router";
import userApi from "../../../api/user";
import { saveEvent } from "../../../api/userevents";
import { useCourse } from "../../../contexts/CourseProvider";
import SCControlAlert from "./SCControlAlert";
import { getItem, setItem } from "../../../utils/persistanceStorage";
import { useNavigate } from "react-router";

const SCControl = ({ courseid, showSCControl }) => {
  const params = useParams();
  const navigate = useNavigate();

  const { experimentGroup } = useCourse();

  const [openAlert, setOpenAlert] = useState(false);

  const buttonLabel = () => {
    if (showSCControl == "control-showsc") {
      return "Personal Dashboard";
    } else if (showSCControl == "control-shownosc") {
      return "Social Dashboard";
    } else {
      return showSCControl;
    }
  };

  const clickHandler = () => {
    saveEvent(-1, courseid, "clicked", {
      item: "btnScChange",
      currentStatus: showSCControl,
      btnLabel: buttonLabel,
      curExperimentGroup: experimentGroup.index,
    });
    setOpenAlert(true);
  };

  const onConfirmChange = () => {
    saveEvent(-1, courseid, "clicked", {
      item: "btnConfirmSCchange",
      btnLabel: buttonLabel,
      curExperimentGroup: experimentGroup.index,
    });

    let nextgroup = 11;
    if (showSCControl == "control-shownosc") {
      nextgroup = 12;
    }
    userApi
      .updateExperimentGroup(courseid, experimentGroup.index, nextgroup)
      .then(() => {
        saveEvent(-1, courseid, "changedsc", {
          item: "btnConfirmSCChange",
          buttonLabel: buttonLabel,
          experimentGroup: experimentGroup.index,
          nextGroup: nextgroup,
        });
      })
      .then(() => {
        return userApi.getCoursesForUser();
      })
      .then((res) => {
        setItem("user-courses", res?.data?.results || []);
      })
      .then(() => {
        setOpenAlert(false);
        navigate("/");
      });
  };

  const onCancelChange = () => {
    saveEvent(-1, courseid, "clicked", { item: "btnCancelScChange", btnLabel: buttonLabel });
    setOpenAlert(false);
  };

  return (
    <div className="flex max-w-screen-lg w-3/5 min-w-3/5">
      {openAlert && <SCControlAlert onConfirm={onConfirmChange} onCancel={onCancelChange} />}
      <div className="flex items-center w-1/3 min-w-1/3">&nbsp;</div>

      <div className="flex items-center">
        <button
          className="bg-blue-500 text-white px-8 py-2 rounded-lg shadow-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 text-lg"
          onClick={clickHandler}
        >
          Activate {buttonLabel()}
        </button>
      </div>
    </div>
  );
};

export default SCControl;
