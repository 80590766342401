import React from "react";
import { deleteAnswer } from "../../../api/postactivity";
import { saveEvent } from "../../../api/userevents";
import xapiverbs from "../../../config/xapiverbs";

const ResetAnswerButton = React.memo(({ activityid, courseid }) => {
  
  const handleClick = () => {
    let text = "Do you want to erase your answers?\nThis will not affect your score.";
    let choice = window.confirm(text);
    if (choice == true) {
      deleteAnswer({ activityid: activityid })
        .then((res) => {
          saveEvent(activityid, courseid, xapiverbs.DELETED, {});
        })
        .then(() => {
          window.location.reload();
        })
        .catch((e) => console.error(e));

      // window.location.reload();
    } else {
      console.log("Cancelled reset");
    }
  };

  return (
    <div
      onClick={handleClick}
      className="bg-warning text-white px-4 py-2 rounded-lg shadow-lg hover:bg-blue-600 active:shadow-sm"
    >
      Delete Answers
    </div>
  );
});

export default ResetAnswerButton;
