import axios from "./axios";
import { saveEvent } from "./userevents";

export const processSimpleActivity = (processObj) => {
  return axios.post("/processsimpleaccessmentactivity", processObj, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const processAccessmentActivity = (processObj) => {
  return axios.post("/processaccessmentactivity", processObj, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const answerOnboardingQuestion = (activityid, answer) => {
  return axios.post("/answer", {activityid, answer}, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export const saveExercise = (processObj) => {
  return axios.post("/updateactivityanswer", processObj, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const saveAnswer = (processObj) => {
  return axios.post("/updateactivityanswer", processObj, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const deleteAnswer = (processObj) => {
  return axios.post("/resetactivityanswer", processObj, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
