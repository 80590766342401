import { useState, useEffect } from "react";
import { useCourse } from "../contexts/CourseProvider";
import { AnimatePresence, motion } from "framer-motion";
import CourseHeader from "../components/knowledgemap/CourseHeader";
import ChooseGoalOrientationView from "./ChooseGoalOrientationView";
import LearningGoal from "../components/knowledgemap/learninggoal/LearningGoal";
import WelcomeScreen from "../components/general/WelcomeScreen";
import generalApi from "../api/general";
import ChooseSCView from "./ChooseSCView";
import { saveEvent } from "../api/userevents";
import userApi from "../api/user";
import { getItem, setItem } from "../utils/persistanceStorage";
import { useNavigate } from "react-router";
import xapiverbs from "../config/xapiverbs";

const KMView = () => {
  const { course, goalOrientation, setGoalOrientation, lgs, activeConcept, experimentGroup } = useCourse();
  const [changeGoalOrientation, setChangeGoalOrientation] = useState(false);
  const [showMessages, setShowMessages] = useState(false);
  const [welcomeActivities, setWelcomeActivities] = useState(false);
  const [welcomeActivityMessageIds, setWelcomeActivityMessageIds] = useState(false);
  const [showSCControl, setShowSCControl] = useState(false);
  const navigate = useNavigate();

  const [messages, setMessages] = useState([]);

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    let extrajson = {
      item: "KnowledgeMap",
      experimentGroup: experimentGroup?.index,
      experimentGroupName: experimentGroup?.name,
      goalOrientation: goalOrientation,
    };
    saveEvent(-1, course.courseid, xapiverbs.LOAD, extrajson);

    const userMessages = generalApi.getMessage("notification", true).then((item) => {
      if (item?.data?.messages?.length >= 1) {
        setMessages(item.data.messages);
        setShowMessages(true);
      }
    });
    return () => {};
  }, []);

  useEffect(() => {
    // The following part checks if any activity should be loaded after welcome screen and stores them in welcome_activities
    const welcome_activities = [];
    const welcome_activity_ids = [];
    for (const item of messages) {
      const extrajson = item.extrajson;
      if (extrajson) {
        try {
          const json_data = JSON.parse(extrajson);
          const activity = json_data.activity;
          if (activity) {
            welcome_activities.push(activity);
            welcome_activity_ids.push(item.eid);
          }
        } catch (error) {
          console.error("Error parsing JSON from welcome messages:", error);
        }
      }
    }
    setWelcomeActivities(welcome_activities);
    setWelcomeActivityMessageIds(welcome_activity_ids);

    return () => {};
  }, [messages]);

  const welcomeClose = () => {
    if (welcomeActivities.length > 0) {
      for (const act of welcomeActivities) {
        if (act == "ChooseGoalOrientationView") {
          setChangeGoalOrientation(true);
        }
        if (act == "SCControl") {
          setShowSCControl(true);
        }
      }
    }

    welcomeActivityMessageIds.map((messageId) => {
      generalApi.setMessageInactive(messageId);
      return null;
    });
    setShowMessages(false);
  };

  const handleChange = (value, completed) => {
    if (!completed) {
      setGoalOrientation(value);
    }

    if (completed) {
      setChangeGoalOrientation(false);
    }
  };

  const handleSCChange = (selected) => {
    let nextgroup = -1;
    if (selected === "personal-dashboard") {
      nextgroup = 11;
    } else if (selected === "class-dashboard") {
      nextgroup = 12;
    }

    saveEvent(-1, course.courseid, "changedsc", { item: "loadingScreen", selected: selected, nextGroup: nextgroup });

    userApi
      .updateExperimentGroup(course.courseid, experimentGroup.index, nextgroup)
      .then(() => {
        return userApi.getCoursesForUser();
      })
      .then((res) => {
        setItem("user-courses", res?.data?.results || []);
      })
      .then(() => {
        setTimeout(() => {
          navigate("/");
        }, 1000);
      })
      .catch((error) => {
        console.error("Error in handleSCChange:", error);
      });
  };

  const handleClick = () => {
    setChangeGoalOrientation(true);
  };

  if (changeGoalOrientation) {
    return <ChooseGoalOrientationView setGoalOrientation={handleChange} />;
  }

  if (showSCControl) {
    return <ChooseSCView onConfirm={handleSCChange} />;
  }

  return (
    <div className="w-full">
      <div className="flex flex-row">
        <CourseHeader handleGoalClick={handleClick} />
      </div>

      {showMessages && <WelcomeScreen messages={messages} onClose={welcomeClose} />}

      {lgs.length < 1 && <div>No content to show yet.</div>}
      {/* Create the rows of learning goals */}
      {lgs.map((lg) => (
        <LearningGoal key={`learning_goal-${lg.lgid}`} lg={lg} />
      ))}
      {/* Slight darkening of the background once there is an activeconcept */}
      <AnimatePresence exitBeforeEnter>
        {activeConcept && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="z-10 fixed top-0 left-0 w-screen h-screen bg-background_fade"
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default KMView;
