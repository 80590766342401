import DOMPurify from "dompurify";
import { useState, useEffect } from "react";
import { useParams } from "react-router";
import generalApi from "../../../api/general";
import StarInputs from "../exercise/StarsInput";
import { saveEvent } from "../../../api/userevents";
import xapiverbs from "../../../config/xapiverbs";
import { convertHtmlEntityToAngleBrackets } from "../../../utils/string";

function ReadAndAnswerOpen({ activityid, questiondata, showAnswer, onSubmit, onLoadExisting }) {
  const sanitizedQuestion = DOMPurify.sanitize(questiondata.text);
  // const sanitizedAnswer = convertHtmlEntityToAngleBrackets(questiondata.answerString);
  const sanitizedAnswer = DOMPurify.sanitize(questiondata.answerString);
  const [answer, setAnswer] = useState("");
  const [feedback, setFeedback] = useState("");
  const [locked, setLocked] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [submittedOnce, setSubmittedOnce] = useState(false);
  const [stars, setStars] = useState(0);
  const params = useParams();

  const handleAnswerChange = (event) => {
    !showAnswer && !locked && setAnswer(event.target.value);
    setLocked(false);
  };

  const unlock = () => {
    setLocked(false);
  };

  const submit = () => {
    onSubmit(activityid, answer, stars);
    onLoadExisting(activityid);
    setLocked(true);
    setSubmittedOnce(true);
  };

  const handleStarClicked = (stars) => {
    setStars(stars);
    onSubmit(activityid, answer, stars);
    saveEvent(activityid, params.courseid, xapiverbs.CHANGED, { stars: stars });
  };

  const updateAnswers = () => {
    const activityAnswers = generalApi
      .getActivityAnswer(activityid, params.courseid)
      .then((activityAnswers) => {
        if (activityAnswers && activityAnswers?.data?.results?.answer?.text) {
          setAnswer(activityAnswers.data.results.answer.text);
          setStars(activityAnswers.data.results.answer.stars);
          onLoadExisting(activityid);
          setSubmittedOnce(true);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const updateFeedback = () => {
    const activityFeedback = generalApi
      .getActivityFeedback(activityid)
      .then((feedback) => {
        if (feedback.data.success) {
          setFeedback(feedback.data.results.text);
        }
        // if (activityAnswers && activityAnswers.data.results.answer.text) {
        //   setAnswer(activityAnswers.data.results.answer.text);
        //   setLocked(true);
        // }
      })
      .catch((e) => {
        console.error(e);
      });
  };
  useEffect(() => {
    updateAnswers();
    updateFeedback();
  }, []);

  return (
    <div className="text-left mx-10">
      <p className="text-gray-500 text-sm mb-0 mt-10">Question</p>
      <hr />

      <p className="mb-4 text-lg font-medium" dangerouslySetInnerHTML={{ __html: sanitizedQuestion }}></p>

      <textarea
        id="answer"
        name="answer"
        rows="4"
        value={answer}
        onChange={handleAnswerChange}
        className="mt-1 p-2 block w-full border rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300 resize-none"
        placeholder="Type your answer here..."
      ></textarea>
      <StarInputs value={stars} onChange={handleStarClicked} />
      {feedback && (
        <div>
          <p className="text-gray-500 text-sm mb-0 mt-10">Feedback</p>
          <hr />
          <p className="mb-4 text-md">{feedback}</p>
        </div>
      )}
      {(showAnswer || submittedOnce) && (
        <div>
          <p className="text-gray-500 text-sm mb-0 mt-10">Answer</p>
          <hr />
          {/* <p className="mb-4 text-md">{questiondata.correct}</p> */}
          <p className="mb-4 text-md" dangerouslySetInnerHTML={{ __html: sanitizedAnswer }}></p>
        </div>
      )}

      {!showAnswer && !locked && (
        <div
          className="btn cursor-pointer bg-accent hover:bg-amber-400 duration-500 text-white font-bold px-4 rounded w-20 text-center"
          onClick={() => submit()}
        >
          Submit
        </div>
      )}
    </div>
  );
}

export default ReadAndAnswerOpen;
