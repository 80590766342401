const SCControlAlert = ({ onConfirm, onCancel }) => {
  return (
    <div>
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div
          className="bg-accent border border-black p-6 rounded-lg w-1/3"
          onClick={(e) => e.stopPropagation()} // Prevent background click from closing modal
        >
          <div className="mb-4 text-txt_secondary text-center text-lg">
            Do you want to switch the Dashboard?
          </div>

          <div className="flex justify-between mb-4 w-1/2 mx-auto">
            <button className="bg-warning text-txt_secondary px-6 py-2 rounded-lg" onClick={() => onCancel()}>
              Cancel
            </button>
            <button className="bg-completed text-txt_secondary px-6 py-2 rounded-lg" onClick={() => onConfirm()}>
              Confirm
            </button>
          </div>

          <div className="text-txt_secondary text-center text-sm">
            You can change your Dashboard settings anytime by clicking this button again.
          </div>
        </div>
      </div>
    </div>
  );
};

export default SCControlAlert;
