import { createContext, useContext, useState, useEffect } from "react";
import { useParams, useLocation } from "react-router";
import generalApi from "../api/general";
import userApi from "../api/user";
import modal_views from "../config/modal_views";
import { useCourse } from "./CourseProvider";
import { saveEvent } from "../api/userevents";
import xapiverbs from "../config/xapiverbs";
const ActivityContext = createContext();

export function ActivityProvider({ children }) {
  const { activities, setActivities, updateConceptScores, conceptScores, experimentGroup, goalOrientation } = useCourse();
  const params = useParams();
  const location = useLocation();

  const [activity, setActivity] = useState(null);
  const [activityData, setActivityData] = useState(null);
  const [activityStatus, setActivityStatus] = useState(null);
  const [childActivities, setChildActivities] = useState(null);

  const [modalView, setModalView] = useState(modal_views._default);

  const [loadingMainInfo, setLoadingMainInfo] = useState(true);
  const [loadingError, setLoadingError] = useState("");

  useEffect(() => {
    getActivityInformation();
    setCorrectModalView();
  }, []);

  /**
   * Gets all the important course information to show the learning goals,
   * constructs, relations and scores.
   */
  const getActivityInformation = async () => {
    setLoadingMainInfo(true);

    const activityDetailRes = await generalApi.getActivityDetails(params.activityid, params.courseid);

    const activityCompletionRes = await userApi.getActivityCompletionStatus(params.activityid);

    if (activityDetailRes.status == 200 && activityCompletionRes.status == 200) {
      setActivity(activityDetailRes.data.mainActivity);
      setChildActivities(activityDetailRes.data.childActivities);

      setActivityStatus({
        ...activityCompletionRes.data,
        retake: location.state?.retake,
      });

      // Parse activity datajson data
      try {
        if (typeof activityDetailRes.data.mainActivity.datajson == "string") {
          console.log("IMPORTANT FOR DEVELOPER: ACTIVITY DATA IS STRING");
          setActivityData(JSON.parse(activityDetailRes.data.mainActivity.datajson));
        } else {
          setActivityData(activityDetailRes.data.mainActivity.datajson);
        }
      } catch (e) {
        setActivityData(null);
      }

      setLoadingMainInfo(false);
    } else {
      setLoadingMainInfo(false);
      setLoadingError("Failed to load activity information.");
    }

    saveEvent(params.activityid, params.courseid, xapiverbs.OPENEND, {
      alreadyCompleted: activityCompletionRes.data["completed"],
      experimentGroup: experimentGroup.index, 
      experimentGroupName: experimentGroup.name, 
      goalOrientation: goalOrientation,
      currentConceptScores: conceptScores,
      updated: true
    });
  };

  /**
   * Mark the activity as completed.
   */
  const markActivityAsCompleted = (id) => {
    let newActivities = activities.map((a) => {
      if (a.activityid == id) {
        return {
          ...a,
          completed: true,
        };
      }
      return a;
    });
    setActivities(newActivities);

    setActivityStatus({ ...activityStatus, completed: true, retake: false });
    updateConceptScores();
  };

  const updateStoredActivityStatus = (id) => {
    let completed = false;
    userApi
      .getActivityCompletionStatus(id)
      .then((res) => {
        completed = res.data.completed;
      })
      .then(() => {
        if (completed === true) {
          markActivityAsCompleted(id);
          updateConceptScores();
        }
      });
    updateConceptScores();
  };

  const getOnboardingActivityStatus = () => {
    let completed = false;
    userApi
      .getOnboardingCompletionStatus()
      .then((res) => {
        completed = res.data.completed;
      })
      .then(() => {
        if (completed === true) {
          return true;
        }
      });
    return false;
  };

  const markPawsReadingAsCompleted = (id) => {
    let isUpdated = false;
    userApi
      .incrementConceptScorePaws(id)
      .then((res) => {
        isUpdated = res.data.updated;
      })
      .then(() => {
        if (isUpdated === true) {
          markActivityAsCompleted(id);
        }
        updateConceptScores();
      });
  };

  /** * Change modal view */
  const changeModalView = (viewMode) => {
    if (viewMode === "fullscreen") {
      setModalView(modal_views._fullscreen);
    } else {
      setModalView(modal_views._default);
    }
  };

  /** * Set correct modal view */
  const setCorrectModalView = (type) => {
    if (
      type == "learning_material_quiz" ||
      type == "learning_material_interactive" ||
      type == "learning_material_exercise" ||
      type == "learning_material_article" ||
      type == "learning_material_video"
    ) {
      setModalView(modal_views._fullscreen);
    } else {
      setModalView(modal_views._default);
    }
  };

  const value = {
    loadingMainInfo,
    loadingError,

    setActivity,
    activity,
    activityData,
    activityStatus,
    childActivities,

    markActivityAsCompleted,

    modalView,
    changeModalView,
    setCorrectModalView,

    updateStoredActivityStatus,
    markPawsReadingAsCompleted,
    getOnboardingActivityStatus,
  };

  return <ActivityContext.Provider value={value}>{children}</ActivityContext.Provider>;
}

export function useActivity() {
  return useContext(ActivityContext);
}
