import DOMPurify from "dompurify";
import { useEffect } from "react";

function ReadAndAnswerText({ activityid, questiondata, onLoadExisting }) {
  // const sanitizedHtml = DOMPurify.sanitize(questiondata.text);
  const sanitizedHtml = questiondata.text;

  useEffect(() => {
    onLoadExisting(activityid);
  }, []);

  return (
    <div
      className="text-left space-y-4 pt-10 max-w-[1200px]"
      dangerouslySetInnerHTML={{
        __html: sanitizedHtml,
      }}
    />
  );
}

export default ReadAndAnswerText;
