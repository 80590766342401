import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useActivity } from "../../../contexts/ActivityProvider";
import { useParams } from "react-router";
import ActivityContent from "../content/ActivityContent";
import ModalHeader from "./ModalHeader";
import xapiverbs from "../../../config/xapiverbs";
import { saveEvent } from "../../../api/userevents";
import Loading from "./../../general/Loading";

const Modal = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { loadingMainInfo, loadingError, modalView, activity, activityStatus } = useActivity();

  useEffect(() => {
    // If the user has succefully loaded the activity, send xapicall to the backend
    if (!loadingMainInfo && !loadingError) {
      saveEvent(params.activityid, params.courseid, xapiverbs.OPENEND);
    }

    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const handleClickOutsideModal = () => {
    if (activity.activitytype)
      if (
        activity.activitytype === "learning_material_quiz" &&
        !activityStatus.completed &&
        window.confirm("Are you sure you want to leave this page? You will lose your progress.")
      ) {
        // If it is a quiz, we don't want to navigate away from the page
        saveEvent(params.activityid, params.courseid, xapiverbs.CLOSED);
        navigate(-1);
      }

    if (activity.activitytype.includes("learning_material_paws")) {
      // do nothing
    }

    // If it is not a quiz, we want to navigate away from the page
    if (
      (activity.activitytype !== "learning_material_quiz") & !activity.activitytype.includes("learning_material_paws") ||
      activityStatus.completed
    ) {
      saveEvent(params.activityid, params.courseid, xapiverbs.CLOSED, xapiverbs.OUTSIDECLICK);
      navigate(-1);
    }
  };

  // Check if the information main information is loaded
  if (loadingMainInfo) return null;

  const modalHeaderContent = () => {
    if (activity.activitytype == "learning_material_readandanswer") {
      return true;
    }
  };

  return (
    <div className="z-[51] fixed top-0 left-0 h-full w-screen bg-background_fade " style={modalView}>
      <div onClick={handleClickOutsideModal} className="z-[51] fixed top-0 left-0 h-screen w-screen" />
      <div
        className="z-[52] relative bg-background rounded overflow-hidden"
        style={{
          height: `calc(100vh - ${modalView.paddingTop} - ${modalView.paddingBottom})`,
          // maxHeight: `calc(100vh - ${modalView.paddingTop} - ${modalView.paddingBottom})`,
        }}
      >
        {!loadingMainInfo && !loadingError && (
          <>
            <ModalHeader showResetButton={modalHeaderContent()} />
            <ActivityContent />
          </>
        )}
        {(loadingMainInfo || loadingError) && <Loading />}
      </div>
    </div>
  );
};

export default Modal;
