export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

export function limitString(string,limit){
  if(string.length > limit){
    return string.slice(0,limit-3) + "...";
  }
  return string;
}

export const convertHtmlEntityToAngleBrackets = (fieldText) => {
  const htmlEntities = /&(lt|gt);/
  const isHtmlEntityUsed = htmlEntities.test(fieldText)
  
  if (isHtmlEntityUsed) {
    return fieldText.replace(/&lt;/g, '<').replace(/&gt;/g, '>')
  } else {
    return fieldText
  }
}