
export const cutOff = { mastery: 9, pass: 4, fail: 2, perf_close: 1, perf_far: 2 };

export const generalConceptFeedback = (concept, conceptScores, scoringMethod, sc) => {
  if (conceptScores){
    let feedback = conceptScoringFeedback(conceptScores.userscore, conceptScores.avg_score, conceptScores.maxscore, scoringMethod, sc)
    return conceptScores && feedback;
  }
  else{
    return "";
  }
}

export const conceptFeedbackFromConceptData = (concept, conceptScores, goalOrientation) => {
  return conceptScores && (conceptFeedback(conceptScores.userscore, conceptScores.avg_score, conceptScores.maxscore, goalOrientation));
}

export const conceptScoringFeedback = (userScore, avg_score, maxscore, scoringMethod, isSocial) => {
  if (userScore <= 0){
    return "";
  }
  let feedback = "";
  if (scoringMethod == "knowledge"){
    feedback += "Your score on this topic is "+Math.round(userScore*100/maxscore)+"%.";
    if (isSocial){
      feedback += "And the average student in the class scores "+Math.round(avg_score*100/maxscore)+"%";
    }
  } else if (scoringMethod == "effort"){
    feedback += "You have attempted <b>"+Math.round(userScore)+"</b> items on this topic."
    if (isSocial){
      feedback += "Average student in your class has attempted <b>"+Math.round(avg_score)+"</b> items.";
    }
    if (userScore>=avg_score){
      feedback += "Some exercises slightly change on every retry. Keep Going!"
    }else{
      feedback += "Keep Going! "
    }
  } else{

  }
  return feedback;
}

export const conceptFeedback = (userScore, avg_score, maxscore, goalOrientation) => {
  if (userScore<=0){
    return "";
  }
  const progress_own = ` Progress: ${Math.round(userScore*100/maxscore)}%. `
  if (goalOrientation.includes("performance")){
    const progress_avg = ` Class Avg: ${Math.round(avg_score*100/maxscore)}%. `
    return progress_own + progress_avg;
  }else{
    return progress_own;
  }
};
