import { useActivity } from "../../../contexts/ActivityProvider";
import { useCourse } from "../../../contexts/CourseProvider";

import ReadAndAnswerBlanks from "./ReadAndAnswerBlanks";
import ReadAndAnswerMAQ from "./ReadAndAnswerMAQ";
import ReadAndAnswerMCQ from "./ReadAndAnswerMCQ";
import ReadAndAnswerOpen from "./ReadAndAnswerOpen";
import ReadAndAnswerText from "./ReadAndAnswerText";

import { processSimpleActivity, saveAnswer, saveExercise } from "../../../api/postactivity";
import { saveEvent } from "../../../api/userevents";
import xapiverbs from "../../../config/xapiverbs";
import { getCurrUnixDate } from "../../../utils/date";
import { getItem } from "../../../utils/persistanceStorage";
import { useEffect, useState } from "react";

function ReadAndAnswerActivity({ data, showAnswers, onUpdateProgress }) {
  const { course, updateConceptScores } = useCourse();
  const { activity, markActivityAsCompleted } = useActivity();
  const [completed, setCompleted] = useState([]);
  const [percentCompleted, setPercentCompleted] = useState(0);

  useEffect(() => {
    onUpdateProgress(percentCompleted);
  }, [percentCompleted]);

  useEffect(() => {
    setPercentCompleted(completed.length / data.length);
  }, [completed]);

  const handleExistingAnswerUpdate = (activityid) => {
    // setCompleted((completed) => [...completed, activityid]);
  };

  const handleAnswered = (activityid, answer, stars=null) => {
    const submitted_activity = data.find((item) => item.activityid === activityid);
    const userData = getItem("user-data");
    const processObject = {
      activityid: activity.activityid,
      sessionid: userData.sessionid,
      startedTimestamp: getCurrUnixDate(),
      endedTimestamp: getCurrUnixDate(),
      courseid: course.courseid,
      childActivities: [{ activityid: activityid, startedTimestamp: getCurrUnixDate(), endedTimestamp: getCurrUnixDate() }],
    };

    if (submitted_activity.activitytype === "readandanswer_mcq") {
      processObject.childActivities[0]["userAnswerOptionIndexes"] = [answer];
      processObject.childActivities[0]["userAnswerString"] = "";
      processObject.childActivities[0]["userBlanks"] = "";
    }
    if (submitted_activity.activitytype === "readandanswer_maq") {
      processObject.childActivities[0]["userAnswerOptionIndexes"] = answer;
      processObject.childActivities[0]["userAnswerString"] = "";
      processObject.childActivities[0]["userBlanks"] = "";
    }
    if (submitted_activity.activitytype === "readandanswer_blanks") {
      processObject.childActivities[0]["userAnswerOptionIndexes"] = [];
      processObject.childActivities[0]["userAnswerString"] = "";
      processObject.childActivities[0]["userBlanks"] = answer;
    }
    if (submitted_activity.activitytype === "readandanswer_open") {
      saveEvent(activityid, course.courseid, xapiverbs.ANSWERED, {});

      saveExercise({ activityid: activityid, text: answer, stars: stars })
        .then((res) => {
          if (res.data.success) {
            setTimeout(() => {}, 1000);
          } else {
            console.error("error", res.data);
          }
        })
        .catch((e) => console.error(e));
    }

    if (submitted_activity.activitytype !== "readandanswer_open") {
      processSimpleActivity(processObject)
        .then((res) => {
          saveEvent(activityid, course.courseid, xapiverbs.ANSWERED, processObject);
          setTimeout(() => {
            updateConceptScores();
          }, 1000);
        })
        .catch((e) => console.error(e));
    }
  };

  return (
    <div className="container max-h-full max-w-full mx-auto overflow-x-hidden overflow-y-auto ">
      {data.map((section, index) => {
        if (section.activitytype === "readandanswer_reading") {
          return (
            <ReadAndAnswerText
              activityid={section.activityid}
              questiondata={section.datajson}
              key={index}
              onLoadExisting={handleExistingAnswerUpdate}
            />
          );
        } else if (section.activitytype === "readandanswer_mcq") {
          return (
            <ReadAndAnswerMCQ
              activityid={section.activityid}
              questiondata={section.datajson}
              showAnswer={showAnswers}
              key={index}
              onSubmit={handleAnswered}
              onLoadExisting={handleExistingAnswerUpdate}
            />
          );
        } else if (section.activitytype === "readandanswer_maq") {
          return (
            <ReadAndAnswerMAQ
              activityid={section.activityid}
              questiondata={section.datajson}
              showAnswer={showAnswers}
              key={index}
              onSubmit={handleAnswered}
              onLoadExisting={handleExistingAnswerUpdate}
            />
          );
        } else if (section.activitytype === "readandanswer_open") {
          return (
            <ReadAndAnswerOpen
              activityid={section.activityid}
              questiondata={section.datajson}
              showAnswer={showAnswers}
              key={index}
              onSubmit={handleAnswered}
              onLoadExisting={handleExistingAnswerUpdate}
            />
          );
        } else if (section.activitytype === "readandanswer_blanks") {
          return (
            <ReadAndAnswerBlanks
              activityid={section.activityid}
              questiondata={section.datajson}
              showAnswer={showAnswers}
              key={index}
              onSubmit={handleAnswered}
              onLoadExisting={handleExistingAnswerUpdate}
            />
          );
        } else {
          return <div key={index}>{section.text}</div>;
        }
      })}

      <br />
      <hr />
      <br />
    </div>
  );
}

export default ReadAndAnswerActivity;
