import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useCourse } from "../../contexts/CourseProvider";
import { Link } from "react-router-dom";
import Dashboard from "./dashboard/Dashboard";
import SCControl from "./sccontrol/SCControl";
import { goalOrientations } from "../../config/goalorientations";
import { isGoalOrientation } from "../../config/experimentgroups";

const CourseHeader = ({ handleGoalClick = null }) => {
  const params = useParams();
  const { course, goalOrientation, showDashboard, showSCControl } = useCourse();

  return (
    <div className="w-full flex flex-row bg-gray-100 gap-2 p-4 shadow-md mx-auto">
      <div className="flex w-2/5">
        <Link to={`/course/${course.courseid}`}>
          <div className="text-3xl font-MS">{course?.coursename}</div>
        </Link>
      </div>

      {showDashboard && showDashboard == "stats" && <Dashboard courseid={course?.courseid} />}
      {showSCControl && <SCControl courseid={course?.courseid} showSCControl={showSCControl} />}

      {isGoalOrientation(goalOrientation) && (
        <div className="relative flex flex-row my-auto max-w-screen-lg w-2/5 min-w-2/5">
          <div className="flex-col w-2/5">&nbsp;&nbsp;</div>
          <div className="bg-gray-100 border border-gray-300 rounded-lg px-4 py-2 shadow-sm relative">
            <span className="text-gray-700 text-lg">
              Goal:{" "}
              <b className="text-gray-900 pr-4">
                {goalOrientations[goalOrientation]["text"].charAt(0) + goalOrientations[goalOrientation]["text"].slice(1)}
              </b>
            </span>

            <button
              className="absolute top-0 transform bg-blue-500 text-white text-lg font-medium rounded-full shadow hover:bg-blue-600 transition h-full w-2/5"
              onClick={handleGoalClick}
            >
              Change
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CourseHeader;
