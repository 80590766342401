export const goalOrientations = {
  "mastery-approach": {
      "text": "Master the Subject",
      "explaination": "Gain deep and comprehensive understanding",
      "image": "https://studylens.science.uu.nl/media/general/ago-map.png"
  },
  "mastery-avoidance": {
      "text": "Learn Just Enough",
      "explaination": "Put enough efforts to learn at least the essential concepts",
      "image": "https://studylens.science.uu.nl/media/general/ago-map.png"
  },
  "performance-approach": {
      "text": "Perform Better than Others",
      "explaination": "Gain top progress and outperform your peers",
      "image": "https://studylens.science.uu.nl/media/general/ago-map.png"
  },
  "performance-avoidance": {
      "text": "Perform Just Like Others",
      "explaination": "Do not fall behind and keep up with the class",
      "image": "https://studylens.science.uu.nl/media/general/ago-map.png"
  }
};


  // export const goalOrientations = {
  //   "mastery-approach":      " ",
  //   "mastery-avoidance":     " Learn Just Enough",
  //   "performance-approach":  " Perform Better than Others",
  //   "performance-avoidance": " Perform Just Like Others",
  // };  