import { CC_CONFIG } from "./conceptcard";
import { RP_CONFIG } from "./resultpage";

// Here all the experiment group specific information is set.
export const EG_CONFIG = [
  {
    index: 0,
    name: "No social comparison",
    scoring: "knowledge",
    cc: CC_CONFIG[0], // Concept card
    rp: RP_CONFIG[0], // Result page
    dashboard: null,
  },
  {
    index: 1,
    name: "Social comparison on demand",
    scoring: "knowledge",
    cc: CC_CONFIG[1], // Concept card
    rp: RP_CONFIG[1], // Result page
    dashboard: null,
  },
  {
    index: 2,
    name: "SCKnowledge",
    scoring: "knowledge",
    cc: CC_CONFIG[2], // Concept card
    rp: RP_CONFIG[2], // Result page
    dashboard: null,
  },
  {
    index: 3,
    name: "SCEffort",
    scoring: "effort",
    cc: CC_CONFIG[2], // Concept card
    rp: RP_CONFIG[2], // Result page
  },

  {
    index: 4,
    name: "Goal orientation adaptations",
    cc: [],
    rp: [],
    "mastery-approach": {
      name: "Mastery approach adaptations",
      cc: CC_CONFIG[4], // Concept card
      rp: RP_CONFIG[0], // Result page
      dashboard: null,
    },
    "mastery-avoidance": {
      name: "Mastery avoidant adaptations",
      cc: CC_CONFIG[5], // Concept card
      rp: RP_CONFIG[0], // Result page
      dashboard: null,
    },
    "performance-approach": {
      name: "Performance approach adaptations",
      cc: CC_CONFIG[6], // Concept card
      rp: RP_CONFIG[0], // Result page
      dashboard: null,
    },
    "performance-avoidance": {
      name: "Performance avoidant adaptations",
      cc: CC_CONFIG[7], // Concept card
      rp: RP_CONFIG[0], // Result page
      dashboard: null,
    },
  },
  {
    index: 5,
    name: "SessionDashboard",
    scoring: "knowledge",
    dashboard: "stats",
    cc: CC_CONFIG[3],
    rp: RP_CONFIG[3],
  },
  {
    index: 6,
    name: "NoSessionDashboard",
    scoring: "knowledge",
    dashboard: null,
    cc: CC_CONFIG[3],
    rp: RP_CONFIG[3],
  },

  {
    index: 7,
    name: "Paws No social comparison",
    cc: CC_CONFIG[8], // Concept card
    rp: RP_CONFIG[2], // Result page
    dashboard: null,
  },
  {
    index: 8,
    name: "Paws Social comparison on demand",
    cc: CC_CONFIG[9], // Concept card
    rp: RP_CONFIG[2], // Result page
    dashboard: null,
  },
  {
    index: 9,
    name: "PawsSocial comparison by default",
    cc: CC_CONFIG[10], // Concept card
    rp: RP_CONFIG[2], // Result page
    dashboard: null,
  },
  {
    index: 66,
    name: "Leaderboard",
    cc: CC_CONFIG[2],
    rp: RP_CONFIG[2],
    dashboard: null,
  },
  {
    index: 11,
    name: "SelectedNoSC",
    cc: CC_CONFIG[0],
    rp: RP_CONFIG[0],
    dashboard: null,
    sccontrol: "control-shownosc",
    scoring: "knowledge",
  },
  {
    index: 12,
    name: "SelectedSC",
    cc: CC_CONFIG[2],
    rp: RP_CONFIG[2],
    dashboard: null,
    sccontrol: "control-showsc",
    scoring: "knowledge",
  }
];

// Enum for easy referencing
export const ExperimentGroup = {
  noSC: EG_CONFIG[0],
  SConDemand: EG_CONFIG[1],
  SCbyDefault: EG_CONFIG[2],
  SCEffort: EG_CONFIG[3],
  goalOrientation: EG_CONFIG[4],
  SessionDashboard: EG_CONFIG[5],
  NoSessionDashboard: EG_CONFIG[6],
  leaderboard: EG_CONFIG[66],
  no5: EG_CONFIG[0],
  no6: EG_CONFIG[0],
  PawsnoSC: EG_CONFIG[7],
  PawsSConDemand: EG_CONFIG[8],
  PawsSCbyDefault: EG_CONFIG[9],
  SelectedNoSC: EG_CONFIG[11],
  SelectedSC: EG_CONFIG[12],
};

export const scoringMethod = (experimentGroupNo) => {
  const groupDetails = EG_CONFIG.find( (group) => group.index === experimentGroupNo);
  return groupDetails.scoring;
}

export const showScControl = (experimentGroupNo) => {
  if (experimentGroupNo == 11 || experimentGroupNo == 12){
    return true;
  }
  return false;
}

export const isGoalOrientation = (experimentGroup) => {
  if (experimentGroup == ExperimentGroup.goalOrientation) {
    return true;
  }
  const matches = [
    "mastery-approach",
    "mastery-avoidance",
    "performance-approach",
    "performance-avoidance",
  ];
  return matches.includes(experimentGroup);
};
