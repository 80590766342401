import { useEffect, useState } from "react";
import { FaExclamation } from "react-icons/fa";
import { ImCheckmark } from "react-icons/im";
import { colors } from "../../../config/colors";
import { goalOrientations as goalSentences } from "../../../config/goalorientations";
import { useCourse } from "../../../contexts/CourseProvider";
import HoveringBox from "../../general/HoveringBox";

const ConceptFeedbackIcon = ({ concept, onFeedbackIcon }) => {
  const { conceptScores, goalOrientation } = useCourse();
  const scores = conceptScores[concept.id];
  const [hovering, setHovering] = useState(false);

  let element = "";

  useEffect(() => {
    if (element?.content) {
      if (typeof(onFeedbackIcon) == 'function'){
        onFeedbackIcon(element?.content.type.name);
      }
    }
  }, [element]);

  if (!scores) return null;

  const user_score = scores.userscore;
  const avg_score = scores.avg_score;

  if (goalOrientation) {
    element = { feedbackGoal: "Your goal is: " + goalSentences[goalOrientation].text, content: "", feedbackText: "" };
  } else {
    element = "";
  }
  let msgSuccess = "You have achieved your goal for this topic";
  let msgFail = "Keep working towards your goal.";

  if (avg_score > 0) {
    if (goalOrientation == "mastery-approach") {
      if (user_score >= 9) {
        element.content = <ImCheckmark color={colors.bar_average} size={16} />;
        element.feedbackText = msgSuccess;
      }
    } else if (goalOrientation == "mastery-avoidance") {
      if (user_score < 6 && avg_score > 0) {
        element.content = <FaExclamation color={colors.bar_average} size={16} />;
        element.feedbackText = msgFail;
      }
    } else if (goalOrientation == "performance-approach") {
      if ((user_score >= 6 && user_score - avg_score >= 2) || (user_score >= 9 && (user_score - avg_score) >= 0)) {
        element.content = <ImCheckmark color={colors.bar_average} size={16} />;
        element.feedbackText = msgSuccess;
      }
    } else if (goalOrientation == "performance-avoidance") {
      if (user_score < 6 && (avg_score >= 6 || avg_score - user_score > 0)) {
        element.content = <FaExclamation color={colors.bar_average} size={16} />;
        element.feedbackText = msgFail;
      }
    }
  }

  // setIconName(concept.id, element?.content.type.name);

  return (
    <div onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
      <div className="py-2 hover:scale-125 hover:opacity-40 disabled:opacity-100">{element.content}</div>

      {hovering && <HoveringBox header={element.feedbackGoal} text={element.feedbackText} concept={concept} />}
    </div>
  );
};

export default ConceptFeedbackIcon;
